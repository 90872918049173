import { States } from "../../app.states";
import {
    Component,
    AppStateService,
    SessionService,
    UrlService,
    CustomerService,
    PaymentCardService,
    ErrorService,
    FeeService
} from "../../core/index";
import { SmsService } from "../index";

import paymentCardStart from "./payment-card-start.scss";
import buttons from "../../styles/buttons.scss";
import creditCardFrom from "./credit-card-form/credit-card-form.scss"
import base from "../../styles/base.scss";
import icons from "../../styles/icons.scss";
import userRegistrationForm from "../shared/components/user-registration-form/user-registration-form.scss";
import errorMessage from "../../shared/components/error-message/error-message.scss";

const styles = {
    paymentCardStart: paymentCardStart,
    buttons: buttons,
    creditCardForm: creditCardFrom,
    base: base,
    icons: icons,
    userRegistrationForm: userRegistrationForm,
    errorMessage: errorMessage
};

@Component({
    selector: "becPaymentCardStart",
    template: require("./payment-card-start.html")
})
export class PaymentCardController {
    static $inject = [
        "$scope", "SessionService", "AppStateService", "UrlService", "CustomerService", "PaymentCardService",
        "SmsService", "$window", "$timeout", "ErrorService", "FeeService", "gettextCatalog"
    ];

    styles = styles;
    feeIsLoading: boolean;
    showDemoCards: boolean;

    private static keyCodeEnter = 13;

    constructor(
        private $scope,
        public session: SessionService,
        public appState: AppStateService,
        private urls: UrlService,
        private customer: CustomerService,
        public authorize: PaymentCardService,
        public sms: SmsService,
        private $window: ng.IWindowService,
        private $timeout,
        private errorService: ErrorService,
        public feeService: FeeService,
        private gettextCatalog: ng.gettext.gettextCatalog) {

        $scope.$watch(() => {
            return this.session.hasTruncatedCards();
        },
            (value) => {
                if (!value && this.authorize.paymentcard) {
                    this.feeService.setCardNumber(this.authorize.paymentcard.cardnumber);
                }
            });

        const self = this;

        (function () {
            let time = null;

            $scope.$watch(
                () => feeService.feeIsLoading,
                value => {
                    if (time && Date.now() - time > 1000) {
                        self.feeIsLoading = value;
                        return;
                    }

                    time = Date.now();

                    $timeout(() => {
                        if (feeService.feeIsLoading === value) {
                            self.feeIsLoading = value;
                            time = null;
                        }
                    }, 1000);
                }
            );
        })();
    }

    $onInit() {
        if (this.session.hasTruncatedCards()) {
            this.feeService.setCardNumber(this.truncatedCardNumber);
        }
    }

    get totalAmount() {
        return this.session.amount + this.feeService.feeAmount;
    }

    get truncatedCardNumber() {
        if (!this.authorize.getActivePaymentOption()) return "";
        return this.authorize.getActivePaymentOption().truncatedcardnumber;
    }

    getSubmitButtonText(): string {
        if (this.session.isCreateSubscription) {
            return this.session.amount
                ? this.gettextCatalog.getString("Signup and Pay")
                : this.gettextCatalog.getString("Signup");
        } else if (this.session.isUpdateSubscription) {
            return this.session.amount
                ? this.gettextCatalog.getString("Renew and Pay")
                : this.gettextCatalog.getString("Renew");
        } else if (this.session.isVerifyAndCreateToken) {
            return this.session.amount
                ? this.gettextCatalog.getString("Add card and Pay")
                : this.gettextCatalog.getString("Add card");
        }
        return this.gettextCatalog.getString("Pay");
    }

    getStoreCardText(): string {
        return this.gettextCatalog
            .getString('By clicking {{submitButtonText}} your card details are stored for future charges',
                {
                    submitButtonText: '"' + this.getSubmitButtonText() + '"'
                });
    }

    isActive(name: string): boolean {
        return !jQuery(name).is(":disabled");
    }

    updatePaymentcard(paymentcard) {
        this.authorize.paymentcard = paymentcard;
        this.feeService.setCardNumber(this.authorize.paymentcard.cardnumber);
    }

    //Test
    onEnterPressed() {
        this.errorService.hideError();
        if (!this.session.isAuthorized && this.isActive("#payBtn")) {
            this.authorize.authorize();
            return true;
        }
    }

    toggleShowTestCards() {
        this.showDemoCards = !this.showDemoCards;
    }

    getDisabledClasses(disabled: boolean) {
        return disabled ? styles.buttons.disabled : "";
    }

    hasError() {
        return this.errorService.errorContext === States.Session.PaymentCard.name;
    }

    removeActiveTruncatedCard() {
        this.authorize.deleteActiveTruncatedCard();
    }

    getIsCardTypeCredit() {
        return this.feeService.paymentTypes &&
            this.feeService.paymentTypes.length > 0 &&
            this.feeService.paymentTypes.find(x => { return x.type.toLowerCase() === "credit" });
    }
}
